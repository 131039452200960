.keyboard {
  margin-top: 10px;
  height: 200px;
  min-height: 200px;
}

.row {
  display: flex;
  margin: 0 auto 8px;
  touch-action: manipulation;
}

.defaultRow {
  @extend .row;
  width: 100%;
}

.secondRow {
  @extend .row;
  padding: 0px 20px;
}
