.boardContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;

  .board {
    display: grid;
    padding: 10px;
    margin: 0 15%;
    box-sizing: border-box;
  }
}
