@import '../../../shared/scss/colors.module.scss';
@import '../../../shared/scss/shared.module.scss';

.dialogContent {
  background-color: $background;
  color: $dimmed-white;
  border: 2px solid $border;
  border-radius: 15px;
  z-index: 998;
  box-sizing: border-box;
  margin-top: 10px;
  .dialogContentWrapper {
    display: flex;

    .dialogChildren {
      @extend .noSelect;
      width: 100%;
      padding: 15px 10px 15px 20px;
      text-align: center;
      height: 100%;
    }

    .closeButton {
      background: none;
      border: none;
      cursor: pointer;
      padding-right: 20px;
      .closeIcon {
        fill: $dimmed-white;
        font-size: 1.05rem;
      }
    }
  }
}
