@import '../../../shared/scss/colors.module.scss';

.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  z-index: 999;
  text-align: left;
}

.dialogContent {
  background-color: $background;
  color: $dimmed-white;
  border: none;
  border-radius: 15px;
  // box-shadow: rgba(60, 64, 67, 0.3) 0 1px 3px 0,
  //   rgba(60, 64, 67, 0.15) 0 4px 8px 3px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  box-sizing: border-box;
}

.dialogContentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.header {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: center;
  color: white;

  .initialHeader {
    text-transform: initial;
  }

  .uppercaseHeader {
    text-transform: uppercase;
  }
}

.closeButton {
  position: absolute;
  background: none;
  border: none;
  cursor: pointer;
  margin-top: 0.15rem;
  top: 1em;
  right: 1em;

  .closeIcon {
    fill: white;
    font-size: 1.05rem;
  }
}

.dialogChildren {
  padding: 0 20px 20px 20px;
  height: 100%;
  overflow: auto;
}
