@import '../../../shared/scss/colors.module.scss';

.settingsDialog {
  width: 100%;
  max-width: 508px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0;
}

.notesContainer {
  width: 70%;
}

.settingsWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.colorBlindSwitchWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .colorBlindSwitchLabel {
    margin: 0px 0px 0.3em 0px;
  }
  .colorBlindSwitchDescription {
    font-size: 0.8em;
    margin: 0;
    color: $light-grey;
  }
}
.feedback {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  .links {
    a:visited,
    a:hover,
    a:link,
    a:active {
      color: $dimmed-white;
    }

    .link {
      padding: 0 8px;
    }
  }
}

.settingsFooter {
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .privacyPolicy {
    a:visited,
    a:hover,
    a:link,
    a:active {
      color: $dimmed-white;
    }
    font-size: 0.8em;
    color: $dimmed-white;
  }

  .copyright {
    font-size: 0.8em;
    margin: 0.3em 0px 0px 0px;
  }

  .version {
    font-size: 0.8em;
  }
}
