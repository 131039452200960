@import '../../../../shared/scss/colors.module.scss';
@import '../../../../shared/scss/shared.module.scss';
@import '../../../../shared/scss/animations.module.scss';

.tile {
  @extend .noSelect;
  border: 2px solid $tile-border;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: calc(1em + 1vmin);
  vertical-align: middle;
  box-sizing: border-box;
  color: white;
  text-transform: uppercase;
  animation-name: none;
  animation-duration: 0s;
  aspect-ratio: 1/1;
  position: relative;

  &[data-tile-color='correct'] {
    @include themed() {
      background: t(correct);
    }
  }
  &[data-tile-color='transparent'] {
    background: transparent;
  }

  // fallback
  @supports not (aspect-ratio: auto) {
    padding-top: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
  }

  span {
    // fallback
    @supports not (aspect-ratio: auto) {
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
      width: auto;
      max-width: 100%;
      height: auto;
    }
  }

  &[data-badge-display='block'] {
    border-color: $light-tile-border;
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: calc(0.5em + 0.1vmin) 0px 0px calc(0.5em + 0.1vmin);
      border-color: transparent transparent transparent $light-tile-border;
      left: -1px;
      bottom: -1px;
      position: absolute;
    }
  }
}

@media screen and (max-width: 350px) {
  .tile {
    font-size: 1em;
  }
}

.animate {
  -webkit-animation-name: popIn;
  -webkit-animation-duration: 0.1s;
  animation-name: popIn;
  animation-duration: 0.1s;
}

.lighterBorder {
  border: 2px solid $light-grey;
}
