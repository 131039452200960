$light-grey: #707070;
$dark-grey: #565758;
$border: #1a1a1a;
$background: #121214;
$yellow: #b59f3b;
$green: #538d4d;
$orange: #e5804a;
$blue: #91bef4;
$white: #fff;
$dimmed-white: #d7dadc;
$tile-border: #3a3a3c;
$light-tile-border: #a5a5aa;
$key-background: #818384;

$themes: (
  default: (
    present: $yellow,
    correct: $green
  ),
  colorBlind: (
    present: $orange,
    correct: $blue
  )
);

:export {
  tileBorder: $tile-border;
  keyBackground: $key-background;
  lightgrey: $light-grey;
  darkgrey: $dark-grey;
  border: $border;
  background: $background;
  white: $white;
  dimmedWhite: $dimmed-white;
}

@mixin themed($themes: $themes) {
  @each $theme, $map in $themes {
    :global(.theme--#{$theme}) & {
      $theme-map: () !global;
      @each $key, $submap in $map {
        $value: map-get(map-get($themes, $theme), '#{$key}');
        $theme-map: map-merge(
          $theme-map,
          (
            $key: $value
          )
        ) !global;
      }

      @content;
      $theme-map: null !global;
    }
  }
}

@function t($key) {
  @return map-get($theme-map, $key);
}
