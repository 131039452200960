@import '../../../shared/scss/colors.module.scss';

.primaryButton {
  @include themed() {
    background: t(present);
  }
  color: white;
  border: none;
  cursor: pointer;
  padding: 2rem 4rem;
  display: inline-flex;
  border-radius: 10px;
  height: 3.5rem;
  width: 10rem;
  min-width: 10rem;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 700;
  white-space: nowrap;
  text-align: center;
}

@media screen and (max-width: 500px) {
  .primaryButton {
    transform: scale(0.8);
  }
}

@media screen and (max-width: 350px) {
  .primaryButton {
    transform: scale(0.7);
  }
}
