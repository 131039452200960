.helpDialog {
  width: 100%;
  max-width: 508px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0;
}

.notesContainer {
  width: 70%;
}

.rowDescription {
  font-size: 0.9em;
}

.exampleRow {
  grid-template-columns: repeat(5, 1fr) !important;
}
