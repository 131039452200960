@import '../../../../shared/scss/colors.module.scss';
@import '../../../../shared/scss/shared.module.scss';

.key {
  @extend .noSelect;
  @extend .transition;
  font-family: inherit;
  font-weight: bold;
  border: 0;
  padding: 0;
  margin: 0 6px 0 0;
  height: 58px;
  border-radius: 4px;
  background-color: $key-background;
  color: $dimmed-white;

  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.3);
}

.absent {
  background-color: $tile-border;
}
