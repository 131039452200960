@import '../../shared/scss/shared.module.scss';
@import '../../shared/scss/colors.module.scss';

.coloredDot {
  @extend .noSelect;
  align-self: center;
  font-size: 3em;

  &[data-tile-color='correct'] {
    @include themed() {
      color: t(correct);
    }
  }
  &[data-tile-color='present'] {
    @include themed() {
      color: t(present);
    }
  }
}

@media screen and (max-width: 500px) {
  .coloredDot {
    font-size: 2.5em;
  }
}

@media screen and (max-width: 350px) {
  .coloredDot {
    font-size: 2em;
  }
}
