@import '../../../shared/scss/colors.module.scss';

.statsDialog {
  width: 90%;
  max-height: 90%;
  overflow-y: auto;
  max-width: 508px;
  margin: 0 auto;
  padding: 0;
  display: flex;
  flex-direction: column;
  border: 2px solid $border;
}

.statsDialog h3 {
  margin: 0.5rem 0 0.5rem;
}

.subtitle {
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  text-transform: capitalize;
}

.statsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 2rem 3rem;
}

.statsItem {
  display: inline-block;
  font-size: 2rem;
  width: 25%;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  text-align: center;
  justify-self: center;
}

.statsItem div {
  font-size: 0.8rem;
}

.buttonsContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-items: center;
  margin-bottom: 30px;
}

@media screen and (max-width: 400px) {
  .statsContainer {
    padding: 0.4rem 0.4rem;
  }
}

.statsChildrenDialog {
  padding: 0;
}
