@import '../../shared/scss/shared.module.scss';


.headerWrapper {
  position: relative;
  place-content: center;
  display: flex;
  align-items: center;
  height: 50px;
  min-height: 50px;

  .header {
    @extend .noSelect;
    font-family: 'Courgette', cursive;
    font-size: 1.4rem;
    font-weight: 200;
    letter-spacing: 0.1rem;
    pointer-events: none;
    color: white;
  }

  h1 {
    position: relative;
  }

  h1:before {
    position: absolute;
    left: 1.2rem;
    top: 1.2em;
    height: 0;
    width: 4rem;
    content: '';
    border-top: 1px solid white;
  }

  @media screen and (max-width: 220px) {
    .header {
      font-size: 1rem;
    }
  }

  .helpButtonWrapper {
    position: absolute;
    left: 0;
    padding: 0px 10px;
  }
}

.headerBtnWrapper {
  position: absolute;
  right: 0;
  white-space: nowrap;
  padding: 0px 10px;
}
