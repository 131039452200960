@import '../../../../../shared/scss/shared.module.scss';
@import '../../../../../shared/scss/animations.module.scss';

.completedRowWrapper {
  display: grid;
  grid-template-columns: 100% auto;
  column-gap: 0.5rem;
  align-items: center;
}

.completedRow {
  @extend .row;
  grid-column: 1;
}

.evaluations {
  -webkit-animation-name: bounceIn;
  -webkit-animation-duration: 0.4s;
  animation-name: bounceIn;
  animation-duration: 0.4s;
  grid-column: 2;

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  align-content: center;
  height: min-content;
  grid-template-rows: 1em 1em;
  gap: 0.3em;

  &[data-game-mode='standard'] {
    & span:nth-child(1) {
      grid-column: 1;
      grid-row: 1;
    }
    & span:nth-child(2) {
      grid-column: 2;
      grid-row: 1;
    }
    & span:nth-child(3) {
      grid-column: 1;
      grid-row: 2;
    }
    & span:nth-child(4) {
      grid-column: 2;
      grid-row: 2;
    }
  }

  &[data-game-mode='expert'] {
    & span:nth-child(1) {
      grid-column: 1;
      grid-row: 1;
    }
    & span:nth-child(2) {
      grid-column: 2;
      grid-row: 1;
    }
    & span:nth-child(3) {
      grid-column: 3;
      grid-row: 1;
    }
    & span:nth-child(4) {
      grid-column: 1;
      grid-row: 2;
    }
    & span:nth-child(5) {
      grid-column: 2;
      grid-row: 2;
    }
    & span:nth-child(6) {
      grid-column: 3;
      grid-row: 2;
    }
  }
}

@media screen and (max-width: 500px) {
  .evaluations {
    gap: 0.3em;
  }
}

@media screen and (max-width: 460px) {
  .evaluations {
    gap: 0.1em;
  }
}

@media screen and (max-width: 350px) {
  .evaluations {
    gap: 0.2em;
  }
}

@media screen and (max-width: 320px) {
  .evaluations {
    gap: 0em;
  }
}

.win {
  animation-name: bounce;
  animation-duration: 1000ms;
}
