@import '../../../../shared/scss/colors.module.scss';

.countdownWrapper {
  color: $dimmed-white;

  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
  height: 3.5rem;
  justify-content: flex-end;
  width: 10rem;
  
  .countdownText {
    font-size: 0.8em;
    margin: 0px;
    text-transform: uppercase;
    text-align: center;
  }
  .countdown {
    font-size: 1.8em;
  }
}
