@import '../../shared/scss/colors.module.scss';

.langingPageWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 80%;
  justify-content: center;

  .standardWrapper {
    margin-bottom: 2rem;
  }

  .buttonDescription {
    color: $dimmed-white;
    text-align: center;
    font-size: 0.9em;
    margin: 0;
    margin-top: 0.4rem;
  }
}
