@import '../../shared/scss/animations.module.scss';
@import '../../shared/scss/colors.module.scss';

.loaderWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  // 198 - keyboard height, 4 rem - header height
  height: calc(100% - 198px - 4rem);

  .loader {
    border: 0.2em solid $dark-grey;
    border-top: 0.2em solid $light-grey;
    border-radius: 50%;
    width: 2em;
    height: 2em;
    animation: spin 1s linear infinite;
  }
}
