@import '../../shared//scss/animations.module.scss';
@import '../../shared//scss/colors.module.scss';

.snackbar {
  position: fixed;
  left: 50%;
  top: 10%;
  transform: translate(-50%, 0);
  min-width: 150px;
  margin: 0 10px;
  height: 30px;
  padding: 10px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $dimmed-white;
  color: black;
  z-index: 1000;

  .message {
    font-weight: bold;
  }
}

.show {
  visibility: visible;
  animation: fadeIn 0.3s, fadeOut 0.3s 2s;
}
.hide {
  visibility: hidden;
}
