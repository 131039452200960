@import '../../shared/scss/colors.module.scss';

$transition-px: 16px;

.switch {
  position: relative;
  display: inline-block;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:focus + .slider {
      box-shadow: 0 0 1px $green;
    }

    &:checked + .slider:before {
      transform: translate(125%, -50%);
    }

    &:checked + .slider {
      background-color: $green;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $dark-grey;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      //left: 3px;
      top: 50%;
      transform: translate(25%, -50%);
      background-color: $white;
      transition: 0.4s;
      left: 0;
    }

    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
  }

  &.normal {
    width: 39px;
    height: 21px;

    .slider:before {
      height: 16px;
      width: 16px;
    }
  }

  &.small {
    width: 20px;
    height: 10px;

    .slider:before {
      height: 8px;
      width: 8px;
    }
  }
}
