@import '../../../../../shared/scss/shared.module.scss';
@import '../../../../../shared/scss/animations.module.scss';

.currentRow {
  @extend .row;
  align-items: center;
}

.shake {
  animation-name: shake;
  animation-duration: 600ms;
}
