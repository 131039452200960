.statisticsButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: inline-flex;
  width: 2.3rem;
}

.buttonSkeleton {
  padding: 0 3px;
  width: 24px;
  height: 24px;
}
