@import '../../../shared/scss/colors.module.scss';
@import '../../../shared/scss/shared.module.scss';

.dialogContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
}

.dialogContent {
  position: absolute;
  width: 90%;
  bottom: -150px;
  background-color: $background;
  color: #fff;
  border: 2px solid $border;
  border-radius: 20px;
  z-index: 998;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;


  .dialogContentWrapper {
    display: flex;

    .svgContainer {
      display: flex;
      margin-left: 10px;
      align-items: center;
      justify-content: center;

      .emoji {
        width: 50px;
        height: 50px;
      }

    }

    .dialogChildren {
      @extend .noSelect;
      padding: 15px 10px 15px 15px;
      text-align: left;
      height: 100%;

      .dialogTitle {
        position: relative;
        font-family: 'Courgette', cursive;
      }

      .dialogTitle:before {
        position: absolute;
        left: 1rem;
        top: 1.1rem;
        height: 0;
        width: 2.5rem;
        content: '';
        border-top: 1px solid white;
      }
    }

    .closeButton {
      background: none;
      border: none;
      cursor: pointer;
      padding-left: 20px;
      padding-right: 10px;
      .closeIcon {
        fill: $dimmed-white;
        font-size: 1.05rem;
      }
    }
  }
}
